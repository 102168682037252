import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ContentLayout from "../components/ContentLayout"
import { Link } from "../components/fragments"

import Icon from "../svg/menu/mi_legyek.svg"

const shortcodes = {
  a: Link,
}

const MiLegyekPage = ({
  data: {
    mdx: { body },
  },
}) => (
  <ContentLayout icon={Icon} title="Mi legyek?">
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  </ContentLayout>
)

export default MiLegyekPage

export const query = graphql`
  query mi_legyek {
    mdx(slug: { eq: "mi-legyek/main" }) {
      body
    }
  }
`
